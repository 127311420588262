<template>
  <div class="container-auction">
    <div class="auction-text">推荐拍卖会</div>
    <div class="auction-img-con-l1">
      <div class="auction-img">
        <div v-for="(item,index) in auctionListDataStart" :key="index">
          <div class="mask-img"></div>
          <a target="_blank" :href="item.url">
            <div class="auction-img-masking"></div>
            <!-- <img class="auction-img-masking" src="../assets/images/矩形.png" alt=""> -->
          </a>
          <div class="auction-img-text">
            <div class="item-text">
              <span>{{item.startTime}}</span>
              <span>|</span>
              <span>{{item.auctionHouseTitle}}</span>
            </div>
            <div class="item-text-a">
              <a :title="item.title" target="_blank" :href="item.url">{{item.title}}</a>
            </div>
          </div>
          <div class="forBackgroundImage">
            <img :src="item.image" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="auction-img-con" @mouseenter="enter()" @mouseleave="leave()">
      <div class="auction-img" :style="{transform: 'translate3d('+scrollLeftRem+'rem,0,0)'}">
        <div v-for="(item,index) in auctionListData" :key="index">
          <div class="mask-img"></div>
          <a target="_blank" :href="item.url">
            <div class="auction-img-masking"></div>
            <!-- <img class="auction-img-masking" src="../assets/images/矩形.png" alt=""> -->
          </a>
          <div class="auction-img-text">
            <div class="item-text">
              <span>{{item.startTime}}</span>
              <span>|</span>
              <span>{{item.auctionHouseTitle}}</span>
            </div>
            <div class="item-text-a">
              <a :title="item.title" target="_blank" :href="item.url">{{item.title}}</a>
            </div>
          </div>
          <div class="forBackgroundImage">
            <img :src="item.image" alt="">
          </div>
        </div>
      </div>
      <div class="scroll-Bar" v-if="auctionListData.length > 3" :style="{visibility: visibility}">
        <span class="demonstration"></span>
        <el-slider v-model="scrollValue" :show-tooltip="false" :max="scrollMax" @input="scrollInput()"></el-slider>
      </div>
      <div class="mrbottom" v-else></div>
    </div>

  </div>
</template>
<script>
  import {
    auctionList
  } from '../api/index'
  let timer = null
  export default {
    name: 'Auction',
    data() {
      return {
        auctionListDate: {
          userid: Number(sessionStorage.getItem('accessId')) || 0,
          title: '',
          address: '',
          time: '',
          auctionType: 22,
          page: 1,
          pageSize: 99,
          auctionWayId: '',
          placeSourceId: '',
          auctionHouseId: '',
          classifyId: ''
        },
        auctionListData: [],
        auctionListDataStart: [],
        scrollLeft: 0,
        scrollLeftRem: 0,
        widthSum: 0,
        scrollValue: 0,
        scrollMax: 0,
        visibility: 'hidden'
      }
    },
    created() {
      this.auctionList()
    },
    destroyed() {
      clearInterval(timer)
    },
    methods: {
      enter() {
        clearInterval(timer)
        this.visibility = 'visible'
      },
      leave() {
        this.openScroll()
        this.visibility = 'hidden'
      },
      scrollInput() {
        this.scrollLeft = this.scrollValue * -1
        this.scrollLeftRem = this.scrollLeft / 192
      },
      openScroll() { // 开始轮播
        timer = setInterval(() => {
          this.scrollLeft--
          if ((this.scrollLeft * -1) > (this.widthSum - 1785)) {
            this.scrollLeft = 0
            this.scrollValue = 0
          }
          this.scrollLeftRem = this.scrollLeft / 192
          this.scrollValue = (this.scrollLeft * -1)
        }, 16)
      },
      getWidthCount() { // 获取轮播图总宽度
        if (this.auctionListData.length > 3) { // 大于一屏
          const endArr = this.auctionListData.slice(0, 3) // 拿取第一屏的数据做无限循环
          this.auctionListData = [...this.auctionListData, ...endArr]
          this.widthSum = this.auctionListData.length * 615 - 60
          this.scrollMax = this.widthSum - 1785
          this.openScroll()
        }
      },
      // 拍卖会数据初始化
      async auctionList() {
        const res = await auctionList(this.auctionListDate)
        if (res.code === 0) {
          if (!res.data.list) return
          this.auctionListDataStart = res.data.list.slice(0, 3)
          this.auctionListData = res.data.list.slice(3)
          this.getWidthCount()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 跳转拍卖会详情
      details(url) {
        // this.$router.push({
        //   path: '/auctiondetails',
        //   name: 'Auctiondetails',
        //   query: {
        //     auctiondetailsId: id
        //   }
        // })
        location.href = url
        // window.location.href = url
      }
    }
  }
</script>
<style lang="less" scoped>
  .container-auction {

    .auction-text {
      height: 90px;
      font-weight: 500;
      font-size: 28px;
      text-align: left;
      box-sizing: border-box;
      margin-left: 67px;
      padding-top: 30px;
      border-bottom: 1px solid #bfc3c4;
      width: 1785px;
      // padding-bottom: 20px;
    }

    p {}

    .auction-img-con-l1 {
      box-sizing: border-box;
      margin-left: 67px;
      width: 1785px;
      overflow: hidden;
      margin-top: 30px;
    }

    .auction-img-con {
      box-sizing: border-box;
      margin-left: 67px;
      width: 1785px;
      overflow: hidden;
      margin-top: 30px;

      .scroll-Bar {
        padding: 20px 12px;

        /deep/.el-slider__bar {
          background-color: #cda156;
        }

        /deep/.el-slider__button {
          border-color: #cda156;
        }
      }

      .mrbottom {
        height: 30px;
      }
    }

    .auction-img {
      text-align: left;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      transform: translate3d(0, 0, 0);

      div:hover .mask-img {
        opacity: 1;
        cursor: default;
        transition: all 0.3s ease-out;
      }

      div {
        display: inline-block;
        width: 555px;
        height: 370px;
        margin: 0 30px;
        position: relative;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        .forBackgroundImage {
          background-size: cover !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          width: 100%;
          z-index: -1;
          margin: 0;
          opacity: 1;
        }

        .mask-img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          bottom: 0;
          background: rgba(78, 78, 78, 0.5);
          color: #ffffff;
          opacity: 0;
          margin: 0;
        }

        img {
          width: 100%;
          height: 100%;
          // object-fit: scale-down;
        }

        .auction-img-masking {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 179px;
          background: linear-gradient(rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.8));
        }

        .auction-img-text {
          width: 495px;
          height: 55px;
          position: absolute;
          bottom: 50px;
          text-align: left;
          left: 20px;
          box-sizing: border-box;

          .item-text {
            display: flex;
            height: 40px;
            margin: 0;
            font-weight: 600;
          }

          .item-text-a {
            display: flex;
            align-items: center;
            height: 40px;
            margin: 0;
          }

          span {
            color: #fff;
            font-size: 20px;
            display: inline-block;
          }

          // span:nth-child(1) {
          //   width: 200px;
          // }
          span:nth-child(2) {
            padding: 0 10px;
          }

          span:nth-child(3) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 200px;
          }

          a {
            display: block;
            // margin-top: 16px;
            font-size: 30px;
            font-weight: 600;
            color: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 470px;
          }

          a:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
</style>
