<template>
  <div class="container-bidding">
    <div class="bidding-text">同步竞拍</div>
    <div class="bidding-main">
      <div v-for="(item, index) in list" :key="index">
        <div class="col-md-3">
          <div class="thumb-container">
            <div class="thumb">
              <router-link target="_blank" :to="{
                  path: '/auctiondetails',
                  query: { auctiondetailsId: item.id }
                }">
                <!-- <a href="javascript:;" @click="bidding(item.id)"> -->
                <img :src="item.image+imgSize(1353,951)" alt="" />
                <!-- </a> -->
              </router-link>
            </div>
          </div>
        </div>
        <!-- <div :style="{background:'url('+item.image+')' } " class="forBackgroundImage" @click="bidding(item.id)"></div> -->
        <div class="bidding-main-right">
          <div class="bidding-main-right-left">
            <div class="bidding-collot">
              <router-link target="_blank" :to="{
                  path: '/auctiondetails',
                  query: { auctiondetailsId: item.id }
                }">
                <!-- <a href="javascript:;" :title="item.chineseTitleAuction" @click="bidding(item.id)"> -->
                {{ item.chineseTitleAuction }}
                <!-- </a> -->
              </router-link>

              <a class="right-collot" @click="biddingCollot(item.id)" v-if="hide">
                <i class="iconfont " :class="[item.status === 1 ? 'icon-aixin' : 'icon-kongaixin']"
                  style="color:rgb(234, 49, 74);font-size:13px"></i>
                <a href="javascript:;" class="click-collot">收藏</a>
              </a>
              <a class="right-collot" @click="startBidding(item.id)" v-if="
                  (item.auctionStatus === 1 && hide) ||
                    (item.auctionStatus === 3 && hide)
                ">
                <i class="iconfont " :class="[
                    item.remindStatus === true
                      ? 'icon-renwutixing'
                      : 'icon-duanxintixing'
                  ]" style="color:rgb(234, 49, 74);font-size:15px;"></i>
                <a href="javascript:;" class="click-collot">{{
                  item.remindStatus === true ? '取消提醒' : '开拍提醒'
                }}</a>
              </a>

              <!-- <a class="right-collot" v-else style="border:1px solid #999">
                <i class="iconfont " :class="[item.remindStatus ===true?'icon-renwutixing':'icon-duanxintixing']"
                  style="color:#999;font-size:15px;"></i>
                <a href="javascript:;" class="click-collot"
                  style="color:#999;">{{item.remindStatus===true?'取消提醒':'开拍提醒'}}</a>
              </a> -->
            </div>
            <router-link style="color:#2c3e50;" target="_blank" :to="{
                path: '/auctionhousedetails',
                query: { auctionhousedetailsid: item.auctionHouseId }
              }">
              {{ item.auctionHouseTitle }}
            </router-link>
            <!-- <a href="javascript:;" :title="item.auctionHouseTitle" @click="auctionhousedetails(item.auctionHouseId)"
              style="color:#2c3e50;">{{item.auctionHouseTitle}}</a> -->
            <!-- " -->
            <p v-if="item.deferment === false">
              <span>{{ item.startTime.replace(/\//g, '-')}}</span>
              <span>|</span>
              <span>北京时间</span>
            </p>
            <a href="javascript:;"
              style="color:#333;font-size:0.104167rem;display:block;padding-bottom:0.104167rem;text-decoration:underline"
              v-else>延期待定</a>
            <p class="yeelow">
              <span>保证金 {{ item.earnestMoney }}</span>
              <span style="padding:0 10px">|</span>
              <span v-if="item.auctionWay === 7" style="color:#CD5656;">直播专场</span>
              <span v-else-if="item.auctionWay === 8" style="color:#cda156;">代拍专场</span>
            </p>
            <p>
              <img src="../assets/images/12.png" alt="" />
              <span>{{ item.location }}</span>
            </p>
          </div>
          <div class="right-browse">
            <router-link target="_blank" class="bidding-main-right-right" v-if="item.auctionStatus === 1" :to="{
                path: '/auctiondetails',
                query: { auctiondetailsId: item.id }
              }">
              预约出价中
            </router-link>

            <router-link target="_blank" class="bidding-main-right-right"
              v-if="item.auctionStatus === 2 && accessTokenShow === false" :to="{
                path: '/buyerlive',
                query: {
                  auctionId: item.id,
                  auctionHouseTitle: item.auctionHouseTitle,
                  title: item.chineseTitleAuction
                }
              }" style="background: #D10000;color:#fff;font-weight:600">
              直播中
            </router-link>
            <router-link target="_blank" class="bidding-main-right-right" v-if="
                item.auctionStatus === 2 &&
                  accessTokenShow &&
                  auctionHouseLoginNO
              " :to="{
                path: '/buyerlive',
                query: {
                  auctionId: item.id,
                  auctionHouseTitle: item.auctionHouseTitle,
                  title: item.chineseTitleAuction
                }
              }" style="background: #D10000;color:#fff;font-weight:600">
              直播中
            </router-link>
            <router-link target="_blank" class="bidding-main-right-right" v-if="
                item.auctionStatus === 2 &&
                  accessTokenShow &&
                  auctionHouseLoginN &&
                  item.whetherSelfAuctionHouse === true
              " :to="{
                path: '/liveauctionhouse',
                query: { liveId: item.id, title: item.chineseTitleAuction }
              }" style="background: #D10000;color:#fff;font-weight:600">
              直播中
            </router-link>
            <a href="javascript:;" class="bidding-main-right-right" v-if="
                item.auctionStatus === 2 &&
                  accessTokenShow &&
                  auctionHouseLoginN &&
                  item.whetherSelfAuctionHouse === false
              " style="background: #D10000;color:#fff;font-weight:600" @click="inoperable()">
              直播中
            </a>
            <router-link target="_blank" class="bidding-main-right-right" v-if="item.auctionStatus === 3" :to="{
                path: '/auctiondetails',
                query: { auctiondetailsId: item.id }
              }">
              拍卖顺延
            </router-link>
            <router-link target="_blank" class="bidding-main-right-right" v-if="item.auctionStatus === 4" :to="{
                path: '/auctiondetails',
                query: { auctiondetailsId: item.id }
              }" style="background: #DEDEDE;">
              已结束
            </router-link>
            <!-- <a href="javascript:;" class="bidding-main-right-right" @click="auctionDetails(item.id)">浏览竞拍</a> -->
            <span class="cut-off" v-if="
                item.overD !== undefined &&
                  item.auctionStatus === 1 &&
                  item.auctionWay === 8 &&
                  item.deferment === false
              ">{{ item.endTime.replace(/\//g, '-') }}委托截止</span>

            <span class="cut-off pd-off" v-if="
                item.overD !== undefined &&
                  item.auctionStatus === 1 &&
                  item.auctionWay === 8 &&
                  item.deferment === false
              "><i style="color:rgb(197, 80, 86);font-style:normal;">{{
                item.overD
              }}</i>天<i style="color:rgb(197, 80, 86);font-style:normal;">{{
                item.overH
              }}</i>时<i style="color:rgb(197, 80, 86);font-style:normal;">{{
                item.overM
              }}</i>分<i style="color:rgb(197, 80, 86);font-style:normal;">{{
                item.overS
              }}</i>秒</span>

            <span class="cut-off pd-off" v-if="
                item.overD1 !== undefined &&
                  item.auctionWay === 7 &&
                  item.overD1 !== -1 &&
                  item.auctionStatus == 1 &&
                  item.deferment === false
              ">
              <i style="font-style:normal">直播倒计时:</i>
              <i style="color:rgb(197, 80, 86);font-style:normal;">{{
                item.overD1
              }}</i>天<i style="color:rgb(197, 80, 86);font-style:normal;">{{
                item.overH1
              }}</i>时<i style="color:rgb(197, 80, 86);font-style:normal;">{{
                item.overM1
              }}</i>分<i style="color:rgb(197, 80, 86);font-style:normal;">{{
                item.overS1
              }}</i>秒</span>
            <span class="cut-off pd-off" v-else-if="
                item.overD === undefined &&
                  item.auctionStatus === 1 &&
                  item.deferment === false
              ">委托已结束</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bidding-all">
      <router-link target="_blank" :to="{ path: '/auctionhome' }">
        查看所有拍卖会
        <a href="javascript:">
          <img src="../assets/images/13.png" alt="" />
        </a>
      </router-link>
    </div>
    <Login ref="login" />
  </div>
</template>
<script>
  import {
    SyncBiddingList,
    collectAuction,
    emindShooting
  } from '../api/index'
  import Login from './login.vue'
  export default {
    name: 'Bidding',
    components: {
      Login
    },
    data() {
      return {
        auctionListDate: {
          userid: Number(localStorage.getItem('accessId')) || 0,
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken'),
          title: '',
          auctionWayId: '',
          placeSourceId: '',
          auctionHouseId: '',
          time: '',
          auctionType: -1,
          page: 1,
          pageSize: 10,
          classifyId: ''
        },
        list: [],
        auctionListData: [],
        hide: true,
        accessTokenShow: false,
        auctionHouseLoginN: false,
        auctionHouseLoginNO: false,
        auctionHouseLoginT: false
      }
    },
    created() {
      if (localStorage.getItem('auctionHouseLoginNum') === '1') {
        this.hide = false
      }
      this.auctionList()
      this.setTimeCount()
      // this.setTimeCountTwo()
      if (localStorage.getItem('accessToken')) {
        this.accessTokenShow = true
      } else {
        this.accessTokenShow = false
      }
      if (localStorage.getItem('auctionHouseLoginNum')) {
        this.auctionHouseLoginT = true
        if (localStorage.getItem('auctionHouseLoginNum') === '1') {
          this.auctionHouseLoginN = true
        } else if (localStorage.getItem('auctionHouseLoginNum') === '2') {
          this.auctionHouseLoginNO = true
        }
      } else {
        this.auctionHouseLoginT = false
      }
    },
    mounted() {},
    methods: {
      imgSize(sizeW, sizeh) {
        return `?x-oss-process=image/resize,m_pad,w_${sizeW},h_${sizeh||sizeW}`
      },
      inoperable() {
        this.$message.error('拍卖行账户不可以进行该操作，请切换至买家账户')
      },
      // 查看所有拍卖会
      all() {
        this.$router.push({
          path: '/auctionhome',
          name: 'Auctionhome'
        })
      },
      // 初始化日期yy-mm-dd
      timeDate() {
        const date = new Date()
        var y = date.getFullYear()
        var m = date.getMonth() + 1
        m = m < 10 ? '0' + m : m
        var d = date.getDate()
        d = d < 10 ? '0' + d : d
        this.auctionListDate.time = y + '-' + m + '-' + d
      },
      // 同步竞拍初始化数据
      async auctionList() {
        this.timeDate()
        const res = await SyncBiddingList(this.auctionListDate)
        if (res.code === 0) {
          this.auctionListData = res.data.list
          this.list = res.data.list
          // for (const i in this.list) {
          //   this.list[i].countDownTime = ''
          //   this.countDown(i)
          // }
        } else {
          this.$message.error(res.msg)
        }
      },
      // 开拍提醒
      async startBidding(auctionHouseId) {
        if (!localStorage.getItem('accessId')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
          return
        }
        const res = await emindShooting({
          userId: Number(localStorage.getItem('accessId')),
          auctionId: Number(auctionHouseId),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.$message.success(res.data.status?'系统将在拍卖会开始前1小时提醒您及时参与竞拍':'取消成功')
          this.auctionList()
        } else {
          this.$message.error(res.msg)
        }
      },
      async biddingCollot(id) {
        if (!localStorage.getItem('accessId')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
          return
        }
        const res = await collectAuction({
          userid: Number(localStorage.getItem('accessId')),
          auctionId: id,
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          if (res.data.status === true) {
            this.$message.success('收藏成功')
          } else {
            this.$message.error('取消成功')
          }
          this.auctionList()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 跳转拍卖会详情
      bidding(id) {
        this.$router.push({
          path: '/auctiondetails',
          name: 'Auctiondetails',
          query: {
            auctiondetailsId: id
          }
        })
      },
      // 跳转拍卖行详情
      auctionhousedetails(id) {
        this.$router.push({
          path: '/auctionhousedetails',
          name: 'Auctionhousedetails',
          query: {
            auctionhousedetailsid: id
          }
        })
      },
      setTimeCount() {
        const Y = new Date().getFullYear()
        const M =
          new Date().getMonth() + 1 - 0 >= 10 ?
          Number(new Date().getMonth()) + 1 :
          '0' + (Number(new Date().getMonth()) + 1)
        const D =
          new Date().getDate() >= 10 ?
          new Date().getDate() :
          '0' + new Date().getDate()
        const h =
          new Date().getHours() >= 10 ?
          new Date().getHours() :
          '0' + new Date().getHours()
        const m =
          new Date().getMinutes() >= 10 ?
          new Date().getMinutes() :
          '0' + new Date().getMinutes()
        const s =
          new Date().getSeconds() >= 10 ?
          new Date().getSeconds() :
          '0' + new Date().getSeconds()
        const date2 = Y + '/' + M + '/' + D + ' ' + h + ':' + m + ':' + s
        setInterval(() => {
          for (let j = 0; j < this.list.length; j++) {
            const now = Math.round(new Date().getTime())
            this.list[j].endTime = this.list[j].endTime.replace(/-/g, "/")
            this.list[j].startTime = this.list[j].startTime.replace(/-/g, "/")
            let tmpProducts = []
            tmpProducts = this.list
            if (this.list[j].endTime !== null && date2 <= this.list[j].endTime) {
              this.$set(
                this.list[j],
                'overtime',
                Date.parse(this.list[j].endTime) - now
              )
              this.$set(
                this.list[j],
                'overD',
                Math.floor(
                  Math.floor(
                    (Date.parse(tmpProducts[j].endTime) - now) /
                    1000 /
                    60 /
                    60 /
                    24
                  )
                )
              )
              this.$set(
                this.list[j],
                'overH',
                Math.floor(
                  ((Date.parse(tmpProducts[j].endTime) - now) / 1000 / 60 / 60) %
                  24
                )
              )
              this.$set(
                this.list[j],
                'overM',
                Math.floor(
                  ((Date.parse(tmpProducts[j].endTime) - now) / 1000 / 60) % 60
                )
              )
              this.$set(
                this.list[j],
                'overS',
                Math.floor(
                  ((Date.parse(tmpProducts[j].endTime) - now) / 1000) % 60
                )
              )
            }
            if (
              this.list[j].startTime !== null &&
              date2 <= this.list[j].startTime
            ) {
              this.$set(
                this.list[j],
                'overtime1',
                Date.parse(this.list[j].startTime) - now
              )
              this.$set(
                this.list[j],
                'overD1',
                Math.floor(
                  Math.floor(
                    (Date.parse(tmpProducts[j].startTime) - now) /
                    1000 /
                    60 /
                    60 /
                    24
                  )
                )
              )
              this.$set(
                this.list[j],
                'overH1',
                Math.floor(
                  ((Date.parse(tmpProducts[j].startTime) - now) /
                    1000 /
                    60 /
                    60) %
                  24
                )
              )
              this.$set(
                this.list[j],
                'overM1',
                Math.floor(
                  ((Date.parse(tmpProducts[j].startTime) - now) / 1000 / 60) % 60
                )
              )
              this.$set(
                this.list[j],
                'overS1',
                Math.floor(
                  ((Date.parse(tmpProducts[j].startTime) - now) / 1000) % 60
                )
              )
            }
          }
        }, 1000)
      },
      // 跳转买家直播
      buyerLive() {
        if (!localStorage.getItem('accessToken')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
        }
      }
      // // 倒计时
      // countDownFun (time) {
      //   // console.log(time)
      //   const startTime = new Date() // 当前时间
      //   const end = new Date(time) // 结束时间
      //   // console.log(end)
      //   const result = parseInt((end - startTime) / 1000) // 计算出豪秒
      //   const d = parseInt(result / (24 * 60 * 60)) // 用总共的秒数除以1天的秒数
      //   let h = parseInt((result / (60 * 60)) % 24) // 精确小时，用去余
      //   const m = parseInt((result / 60) % 60) // 剩余分钟就是用1小时等于60分钟进行趣余
      //   let s = parseInt(result % 60)
      //   // 当倒计时结束时，改变内容
      //   if (result <= 0) {
      //     return '委托已结束'
      //   }
      //   if (h < 10) {
      //     h = '0' + h
      //   }
      //   if (s < 10) {
      //     s = '0' + s
      //   }
      //   if (h === 0 && m === 0) {
      //     return '剩余' + s + '秒'
      //   } else if (h === 0) {
      //     return '剩余' + m + '分' + s + '秒'
      //   } else if (d === 0) {
      //     return '剩余' + h + '时' + m + '分' + s + '秒'
      //   } else {
      //     return '剩余' + d + '天' + h + '时' + m + '分' + s + '秒'
      //   }
      // },

      // // 定时器
      // // 页面多个倒计时 归零时清除
      // countDown (i) {
      //   const that = this
      //   const item = that.list[i]
      //   that.list[i].countDownFn = setInterval(() => {
      //     //  console.log(that.countDownFun(item.endTime))
      //     if (that.countDownFun(item.countDownTime) !== '委托已结束') {
      //       clearInterval(that.list[i].countDownFn) // 清除定时器
      //     } else {
      //       item.countDownTime = that.countDownFun(item.endTime)
      //       that.$set(
      //         that.list,
      //         item.countDownTime,
      //         that.countDownFun(item.endTime)
      //       )
      //     }
      //   }, 1000)
      // }
    }
  }
</script>
<style lang="less" scoped>
  .container-bidding {
    .bidding-text {
      height: 70px;
      font-weight: 500;
      font-size: 28px;
      text-align: left;
      box-sizing: border-box;
      margin-left: 74px;
      // padding-top: 30px;
      border-bottom: 1px solid #bfc3c4;
      width: 1771px;
      line-height: 70px;
    }

    .bidding-main {
      .bidding-main-right:hover {
        box-shadow: none;
      }

      .col-md-3:hover {
        box-shadow: none;
      }

      div:hover {
        box-shadow: 2px 2px 10px 2px rgba(126, 126, 126, 0.2);
      }

      div {
        margin: 20px 75px 0 75px;
        // height: 363.5px;
        border: 1px solid rgb(182, 181, 181);
        // box-shadow: 0px 1px 16px 0px rgba(46, 46, 46, 0.1);
        display: flex;

        .forBackgroundImage {
          background-size: 80% !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          width: 452px;
          // z-index: -1;
          margin: 0;
          opacity: 1;
          // height: 100%;
        }

        .col-md-3 {
          width: 452px;
          margin: 0;
          border: none;
          height: none;

          .thumb-container {
            width: 100%;
            height: 317px;
            position: relative;
            // padding-bottom: 100%;
            margin: 0;
            border: none;

            .thumb {
              position: absolute;
              width: 100%;
              height: 100%;
              text-align: center;
              margin: 0;
              border-top: none;
              border-bottom: none;
              border-left: none;

              a {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;

                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            }
          }
        }

        .bidding-main-right {
          width: 1320px;
          height: 100%;
          margin: 0;
          border: none;
          box-sizing: border-box;
          padding: 0px 30px 0 30px;
          justify-content: space-between;

          .bidding-main-right-left {
            margin: 0;
            border: none;
            box-shadow: none;
            flex-direction: column;
            text-align: left;
            margin-top: 40px;

            // justify-content: center;
            p {
              // margin-top: 20px;
            }

            .yeelow {
              // color: #cda156 !important;
            }

            .bidding-collot {
              margin: 0;
              border: none;
              padding-bottom: 20px;
              align-items: center;

              &:hover {
                box-shadow: none;
              }

              a:nth-child(1) {
                // margin-top: 40px;
                // width: 900px;
                font-size: 24px;
                font-weight: 500;
                color: #333;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;

                &:hover {
                  text-decoration: underline;
                }
              }

              .right-collot {
                // width: 80px;
                height: 24px;
                border: 1px solid #cda156;
                opacity: 0.7;
                border-radius: 4px;
                background: #ffffff;
                margin: 0 0 0 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #cda156;
                padding-bottom: 0;
                padding: 0 9px;

                &:hover {
                  box-shadow: none;
                  text-decoration: none;
                }

                i {
                  font-size: 14px;
                }

                .click-collot {
                  font-size: 14px;
                  color: #cda156;
                  padding-bottom: 0;
                  padding-left: 4px;

                  &:hover {
                    text-decoration: none;
                  }
                }
              }
            }

            a:nth-child(2) {
              font-size: 20px;
              font-weight: 400;
              color: #666;
              padding-bottom: 20px;

              &:hover {
                text-decoration: underline;
              }
            }

            p:nth-child(3) {
              font-size: 20px;
              font-weight: 400;
              color: #666;

              span:nth-child(2) {
                padding: 0 10px;
              }
            }

            p:nth-child(4) {
              font-size: 20px;
              font-weight: 400;
              color: #666;
            }

            p:nth-child(5) {
              font-size: 20px;
              font-weight: 400;
              color: #666;

              img {
                width: 14px;
                height: 20px;
                margin-right: 10px;
                transform: translateY(-2px);
              }
            }
          }

          .right-browse {
            margin: 0;
            border: none;
            flex-direction: column;

            &:hover {
              box-shadow: none;
            }

            .bidding-main-right-right {
              display: flex;
              margin: 0;
              border: none;
              box-shadow: none;
              width: 280px;
              height: 44px;
              background: #cda156;
              border-radius: 8px;
              opacity: 0.7;
              color: #6e5121;
              justify-content: center;
              align-items: center;
              font-size: 18px;
              margin-top: 40px;

              font-weight: 600;
            }

            .start-bidding {
              padding-top: 20px;
              font-size: 18px;
              color: #cda156;
            }

            .cut-off {
              color: #666;
              font-size: 20px;
              padding-top: 20px;
            }

            .pd-off {
              padding-top: 4px;
            }
          }
        }
      }
    }

    .bidding-all {
      margin-top: 37px;
      margin-bottom: 28px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 14px;
        height: 18px;
        transform: translateY(-2px);
      }

      a {
        color: #cda156;
        font-size: 24px;
        margin-right: 10px;
      }
    }
  }
</style>
