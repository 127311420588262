<template>
  <div class="container-recommended-item">
    <div class="recommended-item-text">
      <p>猜你喜欢</p>
      <router-link target="_blank" :to="{path:'/recommendedItemall'}">
        查看更多
        <i class="iconfont icon-gengduo1"></i>
      </router-link>
      <!-- <a href="javascript:" @click="recommendedItemall()">
        查看更多
        <i class="iconfont icon-gengduo1"></i>
      </a> -->
    </div>
    <div class="recommended-item-img">
      <div v-for="(item,index) in recentBrowseDataList" :key="index">
        <div class="recommended-item-img-top">
          <!-- :class="{'active':status,}" -->
          <div class="love" :class="[!item.status?'love':start]" @click="tabImgColor(item,index,item.status)"
            v-if="hide"></div>
          <!-- <div :style="{background:'url('+item.hostImage+')' } " class="forBackgroundImage"
            @click="productdetails(item.id)"></div> -->
          <!-- <img :src="item.hostImage" alt="" @click="productdetails(item)"> -->
          <!-- <div class="forBackgroundImage" @click="productdetails(item.id)">
            <img :src="item.hostImage" alt="">

          </div> -->
          <div class="col-md-3">
            <div class="thumb-container">
              <div class="thumb">
                <router-link target="_blank" :to="{path:'/productdetails',query:{productdetailsId:item.id}}">

                  <!-- <a href="javascript:;" @click="productdetails(item.id)"> -->
                  <img :src="item.hostImage+imgSize(1215)" alt="">
                  <!-- </a> -->
                </router-link>

              </div>
            </div>
          </div>
        </div>
        <div class="recommended-item-img-bottom">
          <router-link target="_blank" :to="{path:'/productdetails',query:{productdetailsId:item.id}}">
            <!-- <a href="javascript:;" @click="productdetails(item.id)" :title="item.title"> -->
            Lot&nbsp;{{item.lot}}:&nbsp;{{item.title}}
            <!-- </a> -->
          </router-link>
          <router-link target="_blank" style="color:#2c3e50;"
            :to="{path:'/auctionhousedetails',query:{auctionhousedetailsid:item.auctionHouseId}}">
            {{item.auctionHouseName}}
            <!-- <a href="javascript:;" @click="auctionhousedetails(item.auctionHouseId)"
            style="color:#2c3e50;">{{item.auctionHouseName}}</a> -->
          </router-link>
          <p>
            <span>{{item.auctionTime}}</span>
            <span>|</span>
            <span>北京时间</span>
          </p>
          <p class="initialPrice-currency">起拍价格：{{item.initialPrice}}&nbsp;{{item.currency}}</p>
        </div>
      </div>
    </div>
    <Login ref="login" />
  </div>
</template>
<script>
  import Login from './login.vue'
  import {
    recentBrowse,
    userCollect
  } from '../api/index'
  export default {
    name: 'RecommendedItem',
    components: {
      Login
    },
    data() {
      return {
        tabImgOne: require('../assets/images/9.png'),
        tabImgtTwo: require('../assets/images/8.png'),
        recentBrowseData: {
          auctionWayId: '',
          brandAuthorId: '',
          stylePeriodId: '',
          materialsCraftId: '',
          placeSourceId: '',
          auctionHouseId: '',
          userid: 0,
          page: 1,
          pageSize: 8
        },
        colorData: 0,
        status: null,
        collectStatus: null,
        recentBrowseDataList: [],
        start: 'active',
        hide: true
      }
    },
    created() {
      this.recentBrowse()
      if (localStorage.getItem('auctionHouseLoginNum') === '1') {
        this.hide = false
      }
    },
    methods: {
      imgSize(sizeW, sizeh) {
        return `?x-oss-process=image/resize,m_pad,w_${sizeW},h_${sizeh||sizeW}`
      },
      tabImgColor(item, index, status) {
        if (!localStorage.getItem('accessToken')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
        } else {
          if (status === true) {
            this.$message.error('取消收藏')
          } else {
            this.$message.success('收藏成功')
          }
          this.recentBrowseDataList[index].status = !this.recentBrowseDataList[index].status
          this.userCollect(item.id)
        }
      },
      // 用户点击收藏
      async userCollect(id) {
        const res = await userCollect({
          itemid: id,
          userid: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.status = res.data.status
          // this.$message.success('收藏成功~')
          // this.recentBrowse()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 推荐拍品初始化数据
      async recentBrowse() {
        const res = await recentBrowse(this.recentBrowseData)
        if (res.code === 0) {
          this.recentBrowseDataList = res.data.list
        } else {
          this.$message.error(res.msg)
        }
      },
      // 跳转更多拍品
      recommendedItemall() {
        this.$router.push({
          path: '/recommendedItemall',
          name: 'RecommendedItemall'
        })
      },
      // 跳转拍品详情
      productdetails(id) {
        // localStorage.setItem('productdetailsId', JSON.stringify(item))
        this.$router.push({
          path: '/productdetails',
          name: 'Productdetails',
          query: {
            productdetailsId: id
          }
        })
      },
      // 跳转拍卖行
      auctionhousedetails(id) {
        this.$router.push({
          path: '/auctionhousedetails',
          name: 'Auctionhousedetails',
          query: {
            auctionhousedetailsid: id
          }
        })
      }
    }

  }
</script>
<style lang="less" scoped>
  .container-recommended-item {
    .recommended-item-text {
      height: 70px;
      font-weight: 500;
      font-size: 28px;
      text-align: left;
      box-sizing: border-box;
      margin: 0 67px;
      // margin-top: 10px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #bfc3c4;
      align-items: center;

      p {
        margin-bottom: 0;
      }

      a {
        color: #cda156;
        font-size: 20px;
        font-weight: 400;
        display: flex;
        align-items: center;
      }
    }

    .recommended-item-img {
      text-align: left;
      // padding: 0 33px;
      margin-left: 24px;

      div:hover {
        box-shadow: 2px 2px 20px 2px rgba(46, 46, 46, 0.1);
      }

      div {
        width: 405px;
        // height: 562px;
        margin: 27px;
        display: inline-block;
        box-shadow: 0px 1px 16px 0px rgba(46, 46, 46, 0.05);
        background: #fff;

        .recommended-item-img-top {
          width: 405px;
          height: 405px;
          margin: 0;
          position: relative;
          overflow: hidden;

          .love:hover {
            background: url("../assets/images/17.png") no-repeat;
            background-size: 40px;
          }

          .col-md-3 {
            width: 100%;
            margin: 0;
            border: none;
            height: none;

            .thumb-container {
              width: 100%;
              height: 405px;
              position: relative;
              // padding-bottom: 100%;
              margin: 0;
              border: none;

              &:hover {
                box-shadow: none;
              }

              .thumb {
                position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
                margin: 0;
                border-top: none;
                border-bottom: none;
                border-left: none;

                &:hover {
                  box-shadow: none;
                }

                a {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  max-width: 100%;
                  max-height: 100%;

                  img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                  }
                }
              }
            }
          }

          .active {
            background: url("../assets/images/17.png") no-repeat !important;
            background-size: 40px !important;
            position: absolute;
            width: 40px;
            height: 40px;
            top: 10px;
            right: 10px;
            margin: 0;
            background-size: 40px;
            border: transparent;
            border-radius: 50%;
          }

          .love {
            position: absolute;
            width: 40px;
            height: 40px;
            top: 10px;
            right: 10px;
            margin: 0;
            background: url("../assets/images/9.png") no-repeat;
            background-size: 40px;
            border: transparent;
            border-radius: 50%;
            z-index: 100;
          }
        }

        .recommended-item-img-bottom {
          width: 100%;
          // height: 263px;
          margin: 0;
          box-sizing: border-box;
          padding: 0 30px;
          text-align: left;
          padding-top: 20px;

          .initialPrice-currency {
            padding-bottom: 20px;
          }

          p {
            margin-top: 20px;
          }

          a:nth-child(1) {
            font-size: 20px;
            font-weight: 600;
            color: #333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;

            &:hover {
              text-decoration: underline;
            }
          }

          a:nth-child(2) {
            font-size: 18px;
            font-weight: 400;
            color: #333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            margin-top: 18px;

            &:hover {
              text-decoration: underline;
            }
          }

          p:nth-child(2) {
            font-size: 18px;
            font-weight: 400;
            color: #2c3e50;
          }

          p:nth-child(3) {
            color: #666;
            font-size: 18px;

            span:nth-child(2) {
              padding: 0 10px;
            }
          }

          p:nth-child(4) {
            font-weight: 500;
            font-size: 18px;
            color: #333;
          }
        }
      }
    }
  }
</style>
