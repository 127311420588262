<template>
  <div>
    <!-- 轮播图 -->
    <RotationPlot />
    <!-- 正在直播 -->
    <LiveBroadcasting />
    <!-- 推荐拍卖会 -->
    <Auction />
    <!-- 推荐拍品 -->
    <RecommendedItem />
    <!-- 近期浏览 -->
    <Browse />
    <!-- 同步竞拍 -->
    <Bidding />
    <!-- 登录 -->
    <Login ref="login" />
  </div>
</template>
<script>
  import RotationPlot from '@/components/RotationPlot.vue'
  import LiveBroadcasting from '@/components/LiveBroadcasting.vue'
  import Auction from '@/components/Auction.vue'
  import RecommendedItem from '@/components/RecommendedItem.vue'
  import Browse from '@/components/Browse.vue'
  import Bidding from '@/components/Bidding.vue'
  import Login from '../components/login.vue'
  export default {
    name: 'Homemain',
    components: {
      RotationPlot,
      LiveBroadcasting,
      Auction,
      RecommendedItem,
      Browse,
      Bidding,
      Login
    },
    // inject: ['reload'],
    data() {
      return {
        loginShow: false
      }
    },
    created() {
      // if (this.$route.query.forget === '1') {
      //   this.$nextTick(() => {
      //     this.$refs.login.open()
      //   })
      // }
    }
  }
</script>
<style lang="less" scoped>
</style>
