<template>
  <div class="recommendPage">
    <!-- <transition name="imgShouldMove"> -->
    <!-- <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(item,index) in swiperData" :key="index">
          <a target="_blank" :href="item.url">
            <img :src="item.image" alt="">
          </a>
        </swiper-slide> -->
    <!--下方小圆点 -->
    <!-- <div class="swiper-pagination" slot="pagination"></div> -->
    <!-- <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div> -->
    <!-- </swiper> -->

    <!-- </transition> -->

    <el-carousel trigger="click" :interval='2500'>
      <el-carousel-item v-for="(item,index) in swiperData" :key="index">
        <a target="_blank" :href="item.url" class="block-1">
          <img :src="item.image" alt="">
        </a>
      </el-carousel-item>
    </el-carousel>

  </div>

</template>
<script>
  import {
    swiper,
    swiperSlide
  } from 'vue-awesome-swiper'
  import 'swiper/dist/css/swiper.css'
  import {
    banner
  } from '../api/index'
  export default {
    name: 'RotationPlot',
    components: {
      swiper,
      swiperSlide
    },
    data() {
      return {
        swiperOption: {
          loop: false,
          speed: 500,
          autoplay: {
            delay: 2500,
            stopOnLastSlide: false,
            disableOnInteraction: false
          },
          effect: 'fade',
          // 显示分页
          pagination: {
            el: '.swiper-pagination',
            clickable: true // 允许分页点击跳转
          }
          // 设置点击箭头
          // navigation: {
          //   nextEl: '.swiper-button-next',
          //   prevEl: '.swiper-button-prev'
          // }
        },

        swiperData: []
      }
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.swiper
      }
    },
    created() {
      this.banner()
    },
    methods: {
      imgSize(sizeW, sizeh) {
        return `?x-oss-process=image/resize,m_pad,w_${sizeW},h_${sizeh||sizeW}`
      },
      // 轮播图初始化数据
      async banner() {
        const res = await banner({
          bannerType: 'web'
        })
        if (res.code === 0) {
          this.swiperData = []
          for (let index = 0; index < res.data.list.length; index++) {
            if (res.data.list[index].image !== '') {
              this.swiperData.push(res.data.list[index])
            }
          }
        } else {
          this.$message.error(res.msg)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .recommendPage {
    border-bottom: 1px solid #bfc3c4;
  }

  // .recommendPage .swiper-container {
  //   position: relative;
  //   width: 100%;
  //   height: 450px;
  //   img {
  //     width: 100%;
  //     height: 100%;
  //     object-fit: cover;
  //   }
  // }
  .block-1 {
    display: block;
    width: 100%;
    height: 450px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .recommendPage .swiper-container .swiper-slide {
    width: 100%;
    line-height: 200px;
    color: #000;
    font-size: 16px;
    text-align: center;
  }

  /deep/.swiper-pagination-bullet-active {
    background: #cda156;
  }

  .recommendPage .swiper-container .swiper-slide img {
    width: 100%;
    height: 100%;
  }

  /deep/ .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .imgShouldMove-enter {
    transition: all 0.5s;
  }

  .imgShouldMove-enter-active {
    transform: translateX(900px);
  }

  // .el-carousel__item h3 {
  //   color: #475669;
  //   font-size: 14px;
  //   opacity: 0.75;
  //   line-height: 150px;
  //   margin: 0;
  // }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  /deep/ .el-carousel__button {
    background-color: #b8aa90;
  }

  // .el-carousel__container {
  //    !important; /*no*/
  // }
  /deep/ .el-carousel__container {
    height: 450px;
  }
</style>
