<template>
  <div class="container-browse" v-show="recentBrowseDataList !== null">
    <div class="browse-text">
      <p>近期浏览</p>
      <div id="components-pagination-demo-mini">
        <a-pagination size="small" :total="total" @change="onChange" :defaultPageSize='pageSize'
          :defaultCurrent='recentBrowseData.page' />
      </div>
    </div>
    <!-- :style="{transform:translate3d(`${moveNum + 'px' + ',' + 0 + 'px' + ',' + 0 + 'px'}`)}" -->
    <div class="slick-list">
      <div class="browse-img" ref="browse">
        <div v-for="(item,index) in recentBrowseDataList" :key="index" class="box-show">
          <div class="love" :class="[!item.status?'love':start]" @click="tabImgColor(item,index,item.status)"
            v-if="hide"></div>
          <div class="col-md-3">
            <div class="thumb-container">
              <div class="thumb">
                <router-link target="_blank" :to="{path:'/productdetails',query:{productdetailsId:item.id}}">
                  <!-- <a href="javascript:;" @click="detailsGo(item.id)"> -->
                  <img :src="item.hostImage" alt="">
                  <!-- </a> -->
                </router-link>
              </div>
            </div>
          </div>
          <router-link target="_blank" class="onlay" :to="{path:'/productdetails',query:{productdetailsId:item.id}}">
            Lot&nbsp;{{item.lot}}:&nbsp;{{item.title}}
          </router-link>

          <!-- <a class="onlay" href="javascript:;" :title="item.title" @click="detailsGo(item.id)">
            Lot&nbsp;{{item.lot}}:&nbsp;{{item.title}}
          </a> -->
        </div>
      </div>
    </div>

    <Login ref="login" />
  </div>
</template>
<script>
import { recentBrowse, userCollect } from '../api/index'
import Login from '../components/login.vue'
export default {
  name: 'Browse',
  components: {
    Login
  },
  data () {
    return {
      recentBrowseData: {
        auctionWayId: '',
        brandAuthorId: '',
        stylePeriodId: '',
        materialsCraftId: '',
        placeSourceId: '',
        auctionHouseId: '',
        userid: 0,
        page: 1,
        pageSize: 30
      },
      current: 1,
      total: 0,
      moveNum: 0,
      recentBrowseDataList: [],
      start: 'active',
      a: true,
      pageSize: 7,
      swiperOption: {
        loop: true,

        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许分页点击跳转
        }
        // 设置点击箭头
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // }
      },
      hide: true
    }
  },
  created () {
    if (localStorage.getItem('accessToken')) {
      this.recentBrowseData.userid = Number(localStorage.getItem('accessId'))
    }
    if (localStorage.getItem('auctionHouseLoginNum') === '1') {
      this.hide = false
    }
    this.recentBrowse()
  },
  methods: {
    // 近期浏览数据初始化
    async recentBrowse () {
      const res = await recentBrowse(this.recentBrowseData)
      if (res.code === 0) {
        this.recentBrowseDataList = res.data.list
        try{
          this.total = res.data.list.length + 1
        }catch(e){}

      } else {
        this.$message.error(res.msg)
      }
    },

    // 收藏
    tabImgColor (item, index, status) {
      if (!localStorage.getItem('accessToken')) {
        this.$nextTick(() => {
          this.$refs.login.open()
        })
      } else {
        if (status === true) {
          this.$message.error('取消收藏')
        } else {
          this.$message.success('收藏成功')
        }
        this.recentBrowseDataList[index].status = !this.recentBrowseDataList[index].status
        this.userCollect(item.id)
      }
    },
    // 用户点击收藏
    async userCollect (id) {
      const res = await userCollect({
        userid: Number(localStorage.getItem('accessId')),
        itemid: id,
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken')
      })
      if (res.code === 0) {
        // this.$message.success('收藏成功~')
        // this.recentBrowse()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 拍品详情跳转
    detailsGo (id) {
      this.$router.push({
        path: '/productdetails',
        name: 'Productdetails',
        query: {
          productdetailsId: id
        }
      })
    },
    // 改变页码
    onChange (page) {
      this.recentBrowse()

      if (page === 1) {
        this.moveNum = 0
      } else if (page === 2) {
        this.moveNum = -1680
      } else if (page === 3) {
        this.moveNum = -3360
      } else if (page === 4) {
        this.moveNum = -5040
      } else if (page === 5) {
        this.moveNum = -7900
      }
      this.$refs.browse.style.transform = `translate3d(${this.moveNum + 'px' + ',' + 0 + 'px' + ',' + 0 + 'px'})`
      // this.recentBrowseData.page = page
    }
  }
}
</script>
<style lang="less" scoped>
.container-browse {
  /deep/ .ant-pagination-item-active {
    border: none;
  }
  #components-pagination-demo-mini .ant-pagination:not(:last-child) {
    margin-bottom: 24px;
  }
  /deep/ .ant-pagination-item-active a {
    color: #cda156;
  }
  /deep/ .ant-pagination-item a:hover {
    color: #cda156;
  }
  /deep/ .ant-pagination-item {
    border: 1px solid #ccc !important;
  }
  /deep/ .ant-collapse {
    background-color: #fff;
    border: none;
    position: relative;
  }
  /deep/ .ant-select {
    width: 80px !important;
  }
  /deep/ .ant-select-selection__placeholder {
    color: rgba(0, 0, 0, 0.65);
  }
  /deep/ .ant-pagination-item-active a {
    color: #fff;
    background: #cda156;
  }
  /deep/ .ant-pagination-item a:hover {
    color: #000;
  }
  /deep/ .ant-pagination-item {
    border: 1px solid #ccc !important;
    margin: 0px 5px;
    a {
      font-size: 13px;
    }
  }
  /deep/ .ant-pagination-prev {
    border: 1px solid #ccc !important;
    margin-right: 5px;
  }
  /deep/ .ant-pagination-next {
    border: 1px solid #ccc !important;
    margin-left: 5px;
  }
  /deep/ .anticon {
    vertical-align: 0;
  }
  /deep/ .ant-pagination-item-active {
    background: #cda156;
  }
  .browse-text {
    height: 70px;
    font-weight: 500;
    font-size: 28px;
    text-align: left;
    box-sizing: border-box;
    margin: 0 67px;

    // padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding-right: 180px;
    border-bottom: 1px solid #bfc3c4;

    p {
      margin-bottom: 0;
    }
    p:nth-child(2) {
      width: 140px;
      height: 20px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
      border-radius: 10px;
    }
  }
  .slick-list {
    overflow: hidden;
    .browse-img {
      display: flex;
      align-items: center;
      padding: 0 45px;
      opacity: 1;
      width: 150000px;

      transition: transform 500ms ease 0s;
      div:hover {
        box-shadow: 2px 2px 20px 2px rgba(46, 46, 46, 0.2);
      }
      .col-md-3:hover {
        box-shadow: none;
      }
      .box-show {
        // display: inline-block;
        width: 260px;
        height: 340px;
        margin: 22px;
        position: relative;
        box-shadow: 0px 1px 16px 0px rgba(46, 46, 46, 0.1);
        .forBackgroundImage {
          height: 260px;
          background-size: 70% !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          width: 260px;
          margin: 0;
          opacity: 1;
        }

        .col-md-3 {
          width: 100%;
          margin: 0;
          border: none;
          height: none;
          .thumb-container {
            width: 100%;
            height: 238px;
            position: relative;
            // padding-bottom: 100%;
            margin: 0;
            border: none;
            &:hover {
              box-shadow: none;
            }
            .thumb {
              position: absolute;
              width: 100%;
              height: 100%;
              text-align: center;
              margin: 0;
              border-top: none;
              border-bottom: none;
              border-left: none;
              &:hover {
                box-shadow: none;
              }
              a {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;
                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            }
          }
        }
        .love:hover {
          background: url("../assets/images/17.png") no-repeat;
          background-size: 40px;
        }
        .love {
          position: absolute;
          width: 40px;
          height: 40px;
          top: 10px;
          right: 10px;
          margin: 0;
          background: url("../assets/images/9.png") no-repeat;
          background-size: 40px;
          border: transparent;
          border-radius: 50%;
          z-index: 10;
        }
        .active {
          background: url("../assets/images/17.png") no-repeat !important;
          background-size: 40px !important;
          position: absolute;
          width: 40px;
          height: 40px;
          top: 10px;
          right: 10px;
          margin: 0;
          background-size: 40px;
          border: transparent;
          border-radius: 50%;
        }
        .browse-img-top {
          // width: 100%;
          width: 260px;
          height: 260px;
          margin: 0;
          box-shadow: none;
          position: relative;
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: scale-down;

            // position: absolute;
            // left: 0;
            // top: 0;
            // right: 0;
            // bottom: 0;
          }
        }
        // .browse-img-top::before {
        //   content: "";
        //   display: block;
        //   padding-top: 100%;
        // }
        .onlay {
          display: block;
          width: 100%;
          padding: 0 20px;
          font-size: 18px;
          color: #333;
          font-weight: 600;
          margin-top: 30px;
          height: 55px;
          // padding-bottom: 20px;
          box-sizing: border-box;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; //第三行

          &:hover {
            text-decoration: underline;
          }
        }
        a:hover {
          text-decoration: underline;
        }
        span:nth-child(2) {
          padding: 0 10px;
        }
      }
    }
  }
}
</style>
